import React from 'react';
import PropTypes from 'prop-types';
import './soundLikeOriginal.scss';
import MemoVideoPlayer from '../../Common/VideoPlayer/VideoPlayer';
import scrollTo from 'gatsby-plugin-smoothscroll';

const SoundLikeOriginal = ({ smTitle, bgTitle, backVideo, backgroundImage, videoURL }) => {
  const backImageUrl = !backgroundImage.childImageSharp ? backgroundImage : backgroundImage.childImageSharp.fluid.src;

  const backImageStyle = {
    backgroundImage: 'url("' + backImageUrl + '")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };

  return (
    <section className="position-relative sound-like mask-dark" style={backImageStyle}>
      <div className="d-none d-md-block">
        <MemoVideoPlayer
          video={{
            url: backVideo,
            platform: 'vimeo',
          }}
          isBackground={true}
          autoplay={true}
          title={false}
          muted={true}
          loop={true}
        />
      </div>
      <div className="container py-7">
        <div className="row justify-content-center">
          <div className="col col-12 text-center">
            <h1 className="h1 text-white">
              <span className="subtitle font-weight-bold">{smTitle}</span><br/>
              {bgTitle}
            </h1>
            <button
              className="mt-4 btn-tg btn-tg-sale"
              onClick={() => scrollTo('#plans')}
            >Get started today</button>
          </div>
          <div className="col col-12 mt-5">
            <div className="shadow-dark">
              <MemoVideoPlayer
                video={{url: videoURL, platform: 'vimeo'}}
                autoplay={false}
                hasControl={true}
                loop={false}
                title={true}
                muted={false}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

SoundLikeOriginal.propTypes = {
  backgroundImage: PropTypes.object,
  backVideo: PropTypes.string,
  smTitle: PropTypes.string,
  bgTitle: PropTypes.string,
  videoURL: PropTypes.string,
};

export default SoundLikeOriginal;
