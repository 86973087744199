export function findPlanByCodeAndInterval(code, interval, plans) {
  if (!Array.isArray(plans)) {
    return;
  }

  return plans.find(plan => plan.code === code && plan.interval === interval);
}

export function getBronzePlan(plans, interval) {
  return findPlanByCodeAndInterval('bronze', interval, plans);
}

export function getSilverPlan(plans, interval) {
  return findPlanByCodeAndInterval('silver', interval, plans);
}

export function getGoldPlan(plans, interval) {
  return findPlanByCodeAndInterval('gold', interval, plans);
}

export function getPlatinumPlan(plans, interval) {
  return findPlanByCodeAndInterval('platinum', interval, plans);
}

export function getKoaclubPlan(plans, interval) {
  return findPlanByCodeAndInterval('koaclub', interval, plans);
}
