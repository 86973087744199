import React from 'react';
import PropTypes from 'prop-types';
import MemoVideoPlayer from '../Common/VideoPlayer/VideoPlayer';

const MembershipHighlights = ({title, videoURL, list}) => {
  return (
    <section className="py-6 bg-primary-200">
      <div className="container">
        <div className="row">
          <div className="col col-12 col-md-6">
            <MemoVideoPlayer
              video={{url: videoURL, platform: 'vimeo'}}
              autoplay={false}
              hasControl={true}
              loop={false}
              title={true}
              muted={false}
            />
          </div>
          <div className="col col-12 col-md-6 pt-4">
            <h2 className="text-primary font-weight-bold">{title}</h2>
            <ul className="pl-0">
              {
                list.map((item, i) => (
                  <li className="icon-guitar mb-1" key={i}>{item}</li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

MembershipHighlights.propTypes = {
  title: PropTypes.string,
  videoURL: PropTypes.string,
  list: PropTypes.array,
};

export default MembershipHighlights;
