import React from 'react';
import PropTypes from 'prop-types';
import PreviewCompatibleImage from '../../../Common/PreviewCompatibleImage';
import MemoVideoPlayer from '../../../Common/VideoPlayer/VideoPlayer';

const MembershipPlan = ({title, benefits, plan, videoBlock}) => {
  return (
    <React.Fragment>
      <section className={`membership my-6 mb-0 my-sm-6 ${plan?.code || ''}`}>
        <div className="container">
          <div className="row">
            <div className="col col-12 mb-3 mb-sm-5">
              <h3 className="h2 text-primary text-center membership-title py-3 mb-0">
                <span className="membership-title-wrapper">{title}</span>
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col col-12 d-flex flex-wrap justify-content-center">
              {
                benefits && benefits.map((item, i) => (
                  <section key={i} className="position-relative card-benefit px-3 py-6 py-xl-7 flex-grow-1">
                    <div>
                      {item && item.imageContainer && (
                      <PreviewCompatibleImage
                        imageInfo={{
                          className: "benefit-img mb-4",
                          image: item.imageContainer.src,
                          alt: item.imageContainer.alt,
                        }}
                      />)}
                      <h3 className="card-title text-primary mb-4">{item.title}</h3>
                    </div>
                    <p className="m-0 mb-auto">{item.description}</p>
                  </section>
                ))
              }
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col text-center mt-3">

	    { plan && plan.id ?
              <a
                href={`${process.env.TOTALLY_LESSONS_URL}/buy?type=plan&id=${plan.id}`}
                className="btn-tg btn-tg-sale"
                target="_blank"
                rel="noopener noreferrer"
              >BUY</a>
	    :
              <a
                href={`${process.env.TOTALLY_LESSONS_URL}/sign-up`}
                className="btn-tg btn-tg-sale"
                target="_blank"
                rel="noopener noreferrer"
              >Sign up</a>
	    }

            </div>
          </div>
        </div>
      </section>
      {
        videoBlock && (
          <section className={`membership mt-6 mb-7 ${plan.code}`}>
            <div className="container">
              <div className="row">
                <div className="col col-12 mb-3 mb-sm-5">
                  <h3 className="h2 text-primary text-center membership-title py-3 mb-0">
                    <span className="membership-title-wrapper">{videoBlock.title}</span>
                  </h3>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col col-12 col-lg-9 text-center">
                  <MemoVideoPlayer
                    video={{url: videoBlock.videoURL, platform: 'vimeo'}}
                    autoplay={false}
                    hasControl={true}
                    loop={false}
                    title={true}
                    muted={false}
                  />
	    { plan && plan.id ?
              <a
                href={`${process.env.TOTALLY_LESSONS_URL}/buy?type=plan&id=${plan.id}`}
                className="btn-tg btn-tg-sale"
                target="_blank"
                rel="noopener noreferrer"
              >BUY!</a>
	    :
              <a
                href={`${process.env.TOTALLY_LESSONS_URL}/sign-up`}
                className="btn-tg btn-tg-sale"
                target="_blank"
                rel="noopener noreferrer"
              >Sign up</a>
	    }
                  
                </div>
              </div>
            </div>
          </section>
        )
      }
    </React.Fragment>
  );
};

MembershipPlan.propTypes = {
  title: PropTypes.string,
  benefits: PropTypes.array,
  plan: PropTypes.object,
  videoBlock: PropTypes.object,
};

export default MembershipPlan;
