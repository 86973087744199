import React from 'react';
import PropTypes from 'prop-types';

const MembershipHeader = ({title, intro, backgroundImage}) => {
  const backImage = !backgroundImage.childImageSharp
    ? backgroundImage
    : backgroundImage.childImageSharp.fluid.src;

  const backImageStyle = {
    backgroundImage: 'url("' + backImage + '")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  };

  return (
    <header style={backImageStyle} className="bg-primary text-center text-white py-12 mask-dark">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col col-12 col-lg-6">
            <h2 className="mb-5 text-uppercase">{title}</h2>
            <p className="mb-0">{intro}</p>
          </div>
        </div>
      </div>
    </header>
  )
};

MembershipHeader.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.string,
  backgroundImage: PropTypes.object,
};

export default MembershipHeader;

