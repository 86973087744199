import React from 'react';
import PropTypes from 'prop-types';
import './flexiblePlans.scss';
import { getBronzePlan, getGoldPlan, getPlatinumPlan } from '../../../helpers/find-plans.helper';
import PreviewCompatibleImage from '../../Common/PreviewCompatibleImage';

const FlexiblePlans = ({ title, plansFromApi, bronze, gold, platinum }) => {
  const interval = 'month';
  const bronzePlan = getBronzePlan(plansFromApi, interval);
  const goldPlan = getGoldPlan(plansFromApi, interval);
  const platinumPlan = getPlatinumPlan(plansFromApi, interval);
  const plansData = [
    { ...bronzePlan, ...bronze },
    { ...goldPlan, ...gold },
    { ...platinumPlan, ...platinum },
  ];

  return (
   <section className="pt-7 pb-4 flexible-plans">
   </section>
  );
};

FlexiblePlans.propTypes = {
  title: PropTypes.string,
  plansFromApi: PropTypes.array,
  bronze: PropTypes.object,
  gold: PropTypes.object,
  platinum: PropTypes.object,
};

export default FlexiblePlans;
