import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { formatPrice } from '../../../helpers/format-price.helper';
import { getBronzePlan, getGoldPlan, getPlatinumPlan } from '../../../helpers/find-plans.helper';
import './plans.scss';

const Plans = ({ smTitle, bgTitle, plansFromApi, bronze, silver, gold, platinum, koaclub }) => {
  //const plansPeriod = 'month'; // Assuming we're working with the monthly period
  const [plansPeriod, setPlansPeriod] = useState('month');

  const bronzePlan = getBronzePlan(plansFromApi, plansPeriod);
  const goldPlan = getGoldPlan(plansFromApi, plansPeriod);
  const platinumPlan = getPlatinumPlan(plansFromApi, plansPeriod);
/*
  const plansData = [
    { ...bronzePlan, title: 'BRONZE', image: '/img/plan-1.png', price: 'FREE' },
    { ...goldPlan, title: 'GOLD', image: '/img/plan-2.png', price: '$24.99' },
    { ...platinumPlan, title: 'PLATINUM', image: '/img/plan-3.png', price: '$37.99' },
  ];
*/
  const plansMonthData = [
    {...bronzePlan, title: 'Bronze', image: '/img/plan-1.png', price: 'FREE', ...bronze },
    {...goldPlan, title: 'Gold', image: '/img/plan-2.png', price: '$24.99', ...gold },
    {...platinumPlan, title: 'Platinum', image: '/img/plan-3.png', price: '$37.99', ...platinum },
  ];

  const plansYearData = [
    {...bronzePlan, title: 'Bronze', image: '/img/plan-1.png', price: 'FREE', ...bronze },
    {...goldPlan, title: 'Gold', image: '/img/plan-2.png', price: '$219.99', ...gold },
    {...platinumPlan, title: 'Platinum', image: '/img/plan-3.png', price: '$319.99', ...platinum },
  ];

  const cookPlansData = () => plansPeriod === 'month' ? plansMonthData : plansYearData;
  return (
    <section className="position-relative plans" id="plans">
      <div className="container pt-7">
        <div className="row">
          <div className="col text-center">
            <h1 className="h1">
              <span className="subtitle font-weight-bold">{smTitle}</span>
              <br />
              {bgTitle}
            </h1>
          </div>
        </div>
        <ul className="nav nav-tabs justify-content-center">
          <li className="nav-item">
            <button className={`nav-link ${plansPeriod==='month' ? "active" : ""}`} onClick={()=>setPlansPeriod('month')}>Monthly</button>
          </li>
          <li className="nav-item">
            <button className={`nav-link ${plansPeriod==='year' ? "active" : ""}`} onClick={()=>setPlansPeriod('year')}>Yearly</button>
          </li>
        </ul>

        <h2 class="text-primary text-center mb-6">Reconnect with your Acoustic Guitar</h2>

        <div className="row justify-content-center">
          <PlansView plansData={cookPlansData()} plansPeriod={plansPeriod}/>
        </div>
      </div>
    </section>
  );
};


Plans.propTypes = {
  smTitle: PropTypes.string,
  bgTitle: PropTypes.string,
  plansFromApi: PropTypes.array,
  bronze: PropTypes.object,
  gold: PropTypes.object,
  platinum: PropTypes.object,
};

export default Plans;
const ShowPromo = React.memo(({promoCodes, setPromoCodes, plan, plansPeriod}) => {
  const [input, setInput] = useState(promoCodes[plan.code]);
  const [showPromoInput, setShowPromoInput] = useState(!!promoCodes[plan.code]);

  useEffect(()=>{
    setInput('');
    setShowPromoInput(false);
  },[plansPeriod]);

  const OnChange = (e) =>{
    e.persist();
    setInput(e.target.value);
    setPromoCodes((previousState) => ({...previousState, [e.target.name]: e.target.value}));
  };

  return (
    <React.Fragment>
      { showPromoInput ?
        <React.Fragment>
          <div className="promo-input-wrapper">
            { input && <label className="label-promo-input" htmlFor={plan.code}>Promo code</label> }
            < input
              maxLength="100"
              name={plan.code}
              className="mb-4 promo-input"
              onChange={OnChange} type = "text"
              value={input}
              placeholder="Enter promo code"
            />
          </div>
        </React.Fragment>
        :
        <button className="mb-4 btn-tg-promo" onClick={()=>setShowPromoInput(!showPromoInput)}>Use promo code</button>
      }
    </React.Fragment>
  )});

const PlansView = React.memo(({plansData, plansPeriod}) => {
  const promoCodesInitialValues = ()=>
    plansData.filter(plan=>!!plan.code).reduce((accumulator, plan)=> ({...accumulator,[plan.code]: ''}),{});

  const [promoCodes, setPromoCodes] = useState(promoCodesInitialValues());

  useEffect(()=>{
    setPromoCodes(promoCodesInitialValues());
    },[plansPeriod]);

  function getMosPopularClass(plan) {
    if (plan.code === 'platinum') {
      return 'most-popular';
    } else if (plan.code === 'koaclub') {
      return 'serious';
    }

    return '';
  }

  return (
    <React.Fragment>
      {
    <section className="bg-light d-flex align-items-center justify-content-center min-vh-100">
      <div className="d-flex flex-column flex-md-row">
        {plansData.map((plan, index) => (
          <div key={index} className="card shadow-sm mb-4 mb-md-0 mx-2" style={{ width: '20rem' }}>
            <div className="card-body text-center">
              <h2 className="card-title font-weight-bold text-dark">{plan.title}</h2>
              <img src={plan.image} alt={`${plan.title} Image`} className="rounded-circle mb-2 icon" />

              <div className="position-relative w-100 mb-4">
                <div className="position-absolute w-100 h-100 d-flex align-items-center justify-content-center pricing">
                  <span className="text-dark font-weight-bold h5">
                  {
                    plan.amount
                      ? <React.Fragment>
                        <span className="amount"><sup>$</sup>{formatPrice(plan.amount)}</span>
                        <span className="ml-1 mt-1">/ {plan.interval}</span>
                      </React.Fragment>
                      : <span className="amount">FREE</span>
                  }
  		  </span>
                </div>
                <img src="/img/ribbon.png" alt="Ribbon Placeholder" className="w-100 ribbon" />
              </div>

	      <h4>{plan.subhead}</h4>

              <ul className="mt-4 text-muted small text-left desc">
                {plan.topList && plan.topList.map((item, i) => (
                  <li key={i} class="desc">{item}</li>
                ))}
              </ul>

              {
                plan.amount
                  ?
                  <React.Fragment>
{/*                   <ShowPromo key={plan.code} promoCodes={promoCodes} setPromoCodes={setPromoCodes} plan={plan} plansPeriod={plansPeriod}/> */}
                   <a
                     href={`${process.env.TOTALLY_LESSONS_URL}/buy?type=plan&id=${plan.id}&coupon=${promoCodes[plan.code]}`}
                     className={`d-block btn-tg btn-tg-orange ${plansPeriod === 'year' ? 'mb-2' : 'mb-4' }`}
                     target="_blank"
                     rel="noopener noreferrer"
                   >BUY NOW</a>
                    { plansPeriod === 'year' && (
                      <a
                        href={`/gifts?type=membership&id=${plan.id}`}
                        className="d-block btn-tg btn-tg-orange btn-tg-secondary mb-4"
                      >Send as Gift</a>
                    )}
                  </React.Fragment>
                  :
                  <a
                    href={`${process.env.TOTALLY_LESSONS_URL}/sign-up`}
                    className="btn-tg btn-tg-orange mb-4 mt-8"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sign up
                  </a>
              }
             
            </div>
          </div>
        ))}
      </div>
    </section>
     }
    </React.Fragment>
  );
});