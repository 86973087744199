import React from 'react';
import PropTypes from 'prop-types';
import './instructors.scss';
import CustomSlider from '../../Common/CustomSlider/CustomSlider';
import PreviewCompatibleImage from '../../Common/PreviewCompatibleImage';

const Instructors = ({ title, backgroundImage, instructorsList }) => {
  const backImage = !backgroundImage.childImageSharp ? backgroundImage : backgroundImage.childImageSharp.fluid.src;

  const backImageStyle = {
    backgroundImage: 'url("' + backImage + '")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };

  const sliderConfig = {
    fade: false,
    slidesToShow: 3,
    autoplaySpeed: 15000,
    className: 'card-slider',
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="bg-primary-650 instructors" style={backImageStyle}>
      <div className="container">
        <div className="row">
          <div className="col py-6">
            <h2 className="text-center text-white mb-6">{title}</h2>
            {instructorsList && instructorsList.length > 0 && (
              <CustomSlider config={sliderConfig}>
                {instructorsList.map((instructor, i) => (
                  <div key={i} className={`px-2 px-md-4 pb-3 card-wrapper`}>
                    <section className="text-white card card-primary">
                      {instructor && instructor.photo && (
                        <div className={`card-img-wrapper`}>
                          {instructor && instructor.photo && (<PreviewCompatibleImage
                            imageInfo={{
                              image: instructor.photo,
                              alt: instructor.name,
                              className: 'card-img-top',
                            }}
                          />)}
                        </div>
                      )}
                      <div className="px-2">
                        <div className="card-body text-center">
                          <h3 className="card-title mt-4">{instructor.name}</h3>
                          <p className="card-text">{instructor.description}</p>
                        </div>
                      </div>
                    </section>
                  </div>
                ))}
              </CustomSlider>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

Instructors.propTypes = {
  title: PropTypes.string,
  backgroundImage: PropTypes.object,
  instructorsList: PropTypes.array,
};

export default Instructors;
